export default function LevelFailedSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 50 60"
      shapeRendering="crispEdges"
      style={{
        display: "block",
        width: 50,
      }}
    >
      <path
        stroke="#000000"
        d="M1 0h48M3 3h6M11 3h6M19 3h5M26 3h3M33 3h6M41 3h5M3 4h1M8 4h1M11 4h1M16 4h1M19 4h1M23 4h1M26 4h1M28 4h1M33 4h1M38 4h1M41 4h1M45 4h1M3 5h1M5 5h4M11 5h1M13 5h2M16 5h1M19 5h2M22 5h2M26 5h1M28 5h1M33 5h1M35 5h4M41 5h1M43 5h2M46 5h1M3 6h1M8 6h1M11 6h1M16 6h1M20 6h1M22 6h1M26 6h1M28 6h1M33 6h1M38 6h1M41 6h1M43 6h2M46 6h1M3 7h1M5 7h4M11 7h1M13 7h2M16 7h1M19 7h2M22 7h2M26 7h1M28 7h3M33 7h1M35 7h4M41 7h1M43 7h2M46 7h1M3 8h1M5 8h1M11 8h1M13 8h2M16 8h1M19 8h1M23 8h1M26 8h1M30 8h1M33 8h1M38 8h1M41 8h1M45 8h2M3 9h3M11 9h6M19 9h5M26 9h5M33 9h6M41 9h5M3 11h44M3 12h1M46 12h1M3 13h44"
      />
      <path
        stroke="#230d2e"
        d="M0 1h1M49 1h1M0 2h1M49 2h1M0 3h1M49 3h1M0 4h1M49 4h1M0 5h1M49 5h1M0 6h1M49 6h1M0 7h1M49 7h1M0 8h1M49 8h1M0 9h1M49 9h1M0 10h1M49 10h1M0 11h1M49 11h1M0 12h1M49 12h1M0 13h1M49 13h1M0 14h1M49 14h1M0 15h1M49 15h1M0 16h1M49 16h1M0 17h1M3 17h44M49 17h1M0 18h1M3 18h1M46 18h1M49 18h1M0 19h1M3 19h1M46 19h1M49 19h1M0 20h1M3 20h1M46 20h1M49 20h1M0 21h1M3 21h1M46 21h1M49 21h1M0 22h1M3 22h1M46 22h1M49 22h1M0 23h1M3 23h1M46 23h1M49 23h1M0 24h1M3 24h1M46 24h1M49 24h1M0 25h1M3 25h1M46 25h1M49 25h1M0 26h1M3 26h1M46 26h1M49 26h1M0 27h1M3 27h1M46 27h1M49 27h1M0 28h1M3 28h1M46 28h1M49 28h1M0 29h1M3 29h1M46 29h1M49 29h1M0 30h1M3 30h1M46 30h1M49 30h1M0 31h1M3 31h1M46 31h1M49 31h1M0 32h1M3 32h1M46 32h1M49 32h1M0 33h1M3 33h1M46 33h1M49 33h1M0 34h1M3 34h1M46 34h1M49 34h1M0 35h1M3 35h1M46 35h1M49 35h1M0 36h1M3 36h1M46 36h1M49 36h1M0 37h1M3 37h1M46 37h1M49 37h1M0 38h1M3 38h1M46 38h1M49 38h1M0 39h1M3 39h1M46 39h1M49 39h1M0 40h1M3 40h1M46 40h1M49 40h1M0 41h1M3 41h1M46 41h1M49 41h1M0 42h1M3 42h1M46 42h1M49 42h1M0 43h1M3 43h1M46 43h1M49 43h1M0 44h1M3 44h1M46 44h1M49 44h1M0 45h1M3 45h1M46 45h1M49 45h1M0 46h1M3 46h1M46 46h1M49 46h1M0 47h1M3 47h1M46 47h1M49 47h1M0 48h1M3 48h1M46 48h1M49 48h1M0 49h1M3 49h1M46 49h1M49 49h1M0 50h1M3 50h1M46 50h1M49 50h1M0 51h1M3 51h1M46 51h1M49 51h1M0 52h1M3 52h1M46 52h1M49 52h1M0 53h1M3 53h1M46 53h1M49 53h1M0 54h1M3 54h1M46 54h1M49 54h1M0 55h1M3 55h1M46 55h1M49 55h1M0 56h1M3 56h44M49 56h1M0 57h1M49 57h1M0 58h1M49 58h1M1 59h48"
      />
      <path stroke="#3c2349" d="M1 1h48" />
      <path
        stroke="#4f365b"
        d="M1 2h48M1 3h2M9 3h2M17 3h2M24 3h2M29 3h4M39 3h2M46 3h3M1 4h2M9 4h2M17 4h2M24 4h2M29 4h4M39 4h2M46 4h3M1 5h2M9 5h2M17 5h2M24 5h2M29 5h4M39 5h2M47 5h2M1 6h2M9 6h2M17 6h3M23 6h3M29 6h4M39 6h2M47 6h2M1 7h2M9 7h2M17 7h2M24 7h2M31 7h2M39 7h2M47 7h2M1 8h2M6 8h5M17 8h2M24 8h2M31 8h2M39 8h2M47 8h2M1 9h2M6 9h5M17 9h2M24 9h2M31 9h2M39 9h2M46 9h3M1 10h48M1 11h2M47 11h2M1 12h2M47 12h2M1 13h2M47 13h2M1 14h48M1 15h48M1 16h48M1 17h2M47 17h2M1 18h2M47 18h2M1 19h2M47 19h2M1 20h2M47 20h2M1 21h2M47 21h2M1 22h2M47 22h2M1 23h2M47 23h2M1 24h2M47 24h2M1 25h2M47 25h2M1 26h2M47 26h2M1 27h2M47 27h2M1 28h2M47 28h2M1 29h2M47 29h2M1 30h2M47 30h2M1 31h2M47 31h2M1 32h2M47 32h2M1 33h2M47 33h2M1 34h2M47 34h2M1 35h2M47 35h2M1 36h2M47 36h2M1 37h2M47 37h2M1 38h2M47 38h2M1 39h2M47 39h2M1 40h2M47 40h2M1 41h2M47 41h2M1 42h2M47 42h2M1 43h2M47 43h2M1 44h2M47 44h2M1 45h2M47 45h2M1 46h2M47 46h2M1 47h2M47 47h2M1 48h2M47 48h2M1 49h2M47 49h2M1 50h2M47 50h2M1 51h2M47 51h2M1 52h2M47 52h2M1 53h2M47 53h2M1 54h2M47 54h2M1 55h2M47 55h2M1 56h2M47 56h2M1 57h48M1 58h48"
      />
      <path
        stroke="#ffd2cc"
        d="M4 4h4M12 4h4M20 4h3M27 4h1M34 4h4M42 4h3M5 12h1M7 12h1M9 12h1M11 12h1M13 12h1M15 12h1M17 12h1M19 12h1M21 12h1M23 12h1M25 12h1M27 12h1M29 12h1M31 12h1M33 12h1M35 12h1M37 12h1M39 12h1M41 12h1M43 12h1M45 12h1"
      />
      <path
        stroke="#ff6540"
        d="M4 5h1M12 5h1M15 5h1M21 5h1M27 5h1M34 5h1M42 5h1M45 5h1M4 6h4M12 6h4M21 6h1M27 6h1M34 6h4M42 6h1M45 6h1M4 7h1M12 7h1M15 7h1M21 7h1M27 7h1M34 7h1M42 7h1M45 7h1M4 12h1M6 12h1M8 12h1M10 12h1M12 12h1M14 12h1M16 12h1M18 12h1M20 12h1M22 12h1M24 12h1M26 12h1M28 12h1M30 12h1M32 12h1M34 12h1M36 12h1M38 12h1M40 12h1M42 12h1M44 12h1"
      />
      <path
        stroke="#f81d00"
        d="M4 8h1M12 8h1M15 8h1M20 8h3M27 8h3M34 8h4M42 8h3"
      />
      <path stroke="#171019" d="M4 18h42" />
      <path
        stroke="#251f27"
        d="M4 19h42M4 20h42M4 21h42M4 22h42M4 23h42M4 24h42M4 25h42M4 26h42M4 27h42M4 28h42M4 29h42M4 30h42M4 31h42M4 32h42M4 33h42M4 34h42M4 35h42M4 36h42M4 37h42M4 38h42M4 39h42M4 40h42M4 41h42M4 42h42M4 43h42M4 44h42M4 45h42M4 46h42M4 47h42M4 48h42M4 49h42M4 50h42M4 51h42M4 52h42M4 53h42M4 54h42M4 55h42"
      />
    </svg>
  );
}
